<template>
  <div class="timetable-row" :style="`--etaColor: ${onTime(item).color}`">
    <div class="timetable-time">
      {{ item.time }}
    </div>

    <div class="timetable-icon">
      <span class="wifi-logo" />
    </div>

    <div class="timetable-text">
      {{ item.destination }}, {{ item.info }}
    </div>

    <div class="timetable-eta">
      {{ onTime(item).text }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'MTimetableRow',
  props: {
    item: Object
  },
  methods: {
    trimDate (dateString) {
      return dateString.slice(11, 16)
    },
    onTime (item) {
      const { delay, cancelled } = item
      if (cancelled) return { text: this.$t('train.timetable.cancelled'), color: '#D81c1C' }
      if (delay) return { text: this.$t('train.timetable.delayed'), color: '#FF8400' }
      return { text: this.$t('on_time'), color: '#86C542' }
    }
  }
}
</script>

<style lang="stylus" scoped>
.timetable-row
  display grid
  grid-template-columns 3em 1.5em auto 4em

.timetable-text
  overflow hidden
  white-space nowrap
  text-overflow ellipsis

.timetable-time
  color var(--etaColor)

.timetable-eta
  color var(--etaColor)
  text-align right

@keyframes wifi {
  0% {
    content ""
  }
  25% {
    content "\f6aa"
  }
  50% {
    content "\f6ab"
  }
  75% {
    content "\f1eb"
  }
}

@keyframes fade-in {
  from {opacity: 0}
  to {opacity: 1}
}

.wifi-logo
  height 20px
  display inline-block
  transform rotate(90deg)
  font-size 85%
  color var(--etaColor)

.wifi-logo::before,
.wifi-logo::after
  font-family "Font Awesome 5 Pro";
  font-weight 550
  position: absolute;
  transform: translateY(-50%) translateX(-50%);

.wifi-logo::before
  content ""
  animation wifi 3s infinite linear
  animation-delay 0.4s

.wifi-logo::after
  content "\f1eb"
  animation fade-in 3s infinite linear

</style>
